<template>
  <div ref="aiCard" class="AiCard">
    <div>
      <div v-if="picture" class="AiCard-imageContainer">
        <ai-image
          v-bind="picture as AiImageProps"
          :loading="lazyLoadImage ? 'lazy' : 'eager'" />
        <ai-chips v-if="label" :label="label" class="AiCard-chips" />
      </div>
      <div v-else class="AiCard-imageContainer AiCard-Placeholder">
        <ai-icon name="photo" :size="32" class="AiCard-Placeholder--Icon" />
      </div>
      <ai-typo
        as="h3"
        variant="heading-04"
        class="AiCard-title"
        :class="titleClass">
        {{ titleCapitalized ? title.toLowerCase() : title }}
      </ai-typo>

      <ai-typo
        v-if="shortText"
        as="p"
        variant="paragraph-04-regular"
        class="AiCard-description">
        {{ displayText }}
      </ai-typo>

      <ai-typo
        v-else
        as="p"
        variant="paragraph-04-regular"
        class="AiCard-description">
        {{ description }}
      </ai-typo>

      <ai-button
        v-if="shortText"
        :class="[
          'AiPrivateEvent-button--chevron',
          { 'AiPrivateEvent-button--chevron-expanded': expanded },
        ]"
        class="PresentationBlock-readMore"
        :label="buttonLabel"
        :variant="AiButtonVariant.Tertiary"
        :icon-right="'chevron' as IconName"
        slim
        :aria-expanded="expanded"
        :aria-controls="`description-` + slugify(title)"
        :aria-label="readMoreAriaLabel"
        @click="toggleExpanded" />

      <slot />
    </div>

    <div v-if="slots.actions" class="AiCard-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AiButtonVariant } from '../../atoms/AiButton/constants';
import type { IconName } from '../../atoms/AiIcon/types';

import { slugify } from '~~/helpers/index';
import type { AiPicture } from '~~/domains/graphql';

type Props = {
  title: string;
  titleCapitalized: boolean;
  description?: string;
  picture?: AiImageProps | AiPicture;
  label?: string;
  lazyLoadImage?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  titleCapitalized: false,
  description: undefined,
  picture: undefined,
  label: undefined,
});
const slots = useSlots();
const { t } = useI18n();
const aiCard = ref<HTMLElement | undefined>();

const buttonLabel = computed<string>(() =>
  expanded.value
    ? t('ux.organisms.carouselWithDescription.description.readLessCTA')
    : t('ux.organisms.carouselWithDescription.description.readMoreCTA'),
);
const readMoreAriaLabel = computed(() => props.title + ' ' + buttonLabel.value);

const { expanded, shortText, displayText, toggleExpanded } = useExpandableText(
  props?.description ?? '',
  250,
  300,
  aiCard,
);

const titleClass = computed(() => {
  return {
    'AiCard-title--capitalized': props.titleCapitalized,
    'AiCard-title--first-letter-upper': !props.titleCapitalized,
  };
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AiCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AiCard-imageContainer {
  position: relative;
  height: func.calcRem(210);
  margin-bottom: constants.$margin-02;

  @media (mq.$from-medium) {
    margin-bottom: constants.$margin-03;
  }

  .AiCard-chips {
    position: absolute;
    bottom: constants.$margin-01;
    left: constants.$margin-01;
  }
}

.AiCard-title {
  margin-bottom: constants.$margin-00;

  text-transform: lowercase;
  &--first-letter-upper {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--capitalized {
    text-transform: capitalize;
  }
}

.AiCard-description {
  margin-bottom: constants.$margin-01;
}
.AiCard-Placeholder {
  background: colors.$neutral-300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AiCard-Placeholder--Icon {
  color: colors.$neutral-500;
}
</style>
