<template>
  <component
    :is="cardAsLink && props.detailLink ? 'a' : 'div'"
    :href="cardAsLink ? props.detailLink : undefined"
    :target="cardAsLink ? urlTarget : undefined"
    :rel="cardAsLink && urlTarget === '_blank' ? 'noopener noreferrer' : ''"
    class="AiCarouselOverflowItem"
    @click="cardAsLink ? handleClick : undefined">
    <nuxt-link
      v-if="!cardAsLink && imageClickable === 'link'"
      :class="{
        'AiCarouselOverflowItem-background--clickable': props.detailLink,
      }"
      :to="props.detailLink"
      class="AiCarouselOverflowItem-background">
      <ai-image
        v-if="picture?.src"
        :alt="picture?.alt as string"
        :lg="20"
        :loading="lazyLoadPicture ? 'lazy' : 'eager'"
        :md="35"
        :sm="65"
        :src="picture?.src"
        :src-set="picture?.srcSet as string"
        :xl="25"
        class="AiCarouselOverflowItem-image"
        decorative />
      <div class="AiCarouselOverflowItem-gradient" />
    </nuxt-link>

    <component
      :is="imageClickable === 'button' ? 'button' : 'div'"
      v-else
      :class="{
        'AiCarouselOverflowItem-background--clickable': props.detailLink,
      }"
      class="AiCarouselOverflowItem-background"
      @click="imageClickable === 'button' ? handleClick : undefined">
      <ai-image
        v-if="picture?.src"
        :alt="picture?.alt as string"
        :loading="lazyLoadPicture ? 'lazy' : 'eager'"
        :src="picture?.src"
        :src-set="picture?.srcSet as string"
        class="AiCarouselOverflowItem-image"
        decorative />
      <div class="AiCarouselOverflowItem-gradient" />
    </component>

    <div class="AiCarouselOverflowItem-content">
      <slot name="top" />
      <ai-typo
        :class="`AiCarouselOverflowItem-title--${margin}`"
        :variant="titleVariantTypo"
        as="p"
        class="AiCarouselOverflowItem-title">
        {{ title }}
      </ai-typo>

      <ai-typo
        as="p"
        class="AiCarouselOverflowItem-description"
        variant="paragraph-04-regular">
        {{ description }}
      </ai-typo>

      <slot name="button">
        <div class="AiCarouselOverflowItem-buttonWrapper">
          <ai-button
            v-if="detailLink"
            :href="url"
            :icon-right="'button-arrow-right' as IconName"
            :label="ctaLabel"
            :rel="urlTarget === '_blank' ? 'noopener noreferrer' : undefined"
            :target="urlTarget"
            class="AiCarouselOverflowItem-button"
            light
            variant="tertiary"
            @click.stop="noop" />
        </div>
      </slot>
    </div>
  </component>
</template>

<script lang="ts" setup>
import noop from 'lodash/noop';

import type AiButton from '../../atoms/AiButton/AiButton.vue';
import type { IconName } from '../../atoms/AiIcon/types';
import type AiImage from '../../atoms/AiImage/AiImage.vue';
import type AiTypo from '../../atoms/AiTypo/AiTypo.vue';
import type { AiTypoVariant } from '../../atoms/AiTypo/types';

import type { AiPicture } from '~~/domains/graphql';

interface Props {
  title: string;
  description: string;
  ctaLabel?: string;
  picture?: AiPicture;
  detailLink?: string;
  cardAsLink?: boolean;
  titleVariantTypo?: AiTypoVariant;
  margin?: 'margin-01' | 'inner-01';
  lazyLoadPicture?: boolean;
  imageClickable?: 'button' | 'link';
}

const props = withDefaults(defineProps<Props>(), {
  ctaLabel: '',
  detailLink: '',
  margin: 'margin-01',
  titleVariantTypo: 'heading-04',
  imageClickable: undefined,
  picture: undefined,
});

const config = useRuntimeConfig();

const url = computed(() => props.detailLink || undefined);
const urlTarget = computed(() => {
  if (!url.value) return undefined;

  if (url.value.startsWith('/')) return '_self';

  try {
    const location = new URL(url.value);
    const baseLocation = new URL(config.public.baseUrl);

    return location.host !== baseLocation.host ? '_blank' : '_self';
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.warn(e);
  }

  return undefined;
});

const handleClick = () => {
  if (!props.detailLink) return;

  const targetUrl = new URL(props.detailLink);

  if (targetUrl.host !== window.location.host) {
    window.open(props.detailLink, urlTarget.value);
  } else {
    window.location.href = props.detailLink;
  }
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/mixins';

.AiCarouselOverflowItem {
  text-decoration: none;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include mixins.rem-fallback(min-height, 480);

  @media (mq.$from-medium) {
    @include mixins.rem-fallback(min-height, 552);
  }
}

.AiCarouselOverflowItem-background {
  position: absolute;
  inset: 0;
  z-index: 0;
  overflow: hidden;

  &--clickable:hover {
    .AiCarouselOverflowItem-image {
      transform: scale(1.1);
    }
  }
}

.AiCarouselOverflowItem-gradient {
  position: absolute;
  inset: 0px;
  background: constants.$linear-gradient-smoke;
  z-index: 1;
}

.AiCarouselOverflowItem-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  transition: all 300ms;
  @include mixins.rem-fallback(height, 480);

  z-index: 0;

  @media (mq.$from-medium) {
    @include mixins.rem-fallback(height, 552);
  }
}

.AiCarouselOverflowItem-content {
  z-index: 5;
  padding: constants.$padding-04 constants.$padding-01;
  text-align: center;
  min-height: calc(480px / 2);
  text-overflow: ellipsis;

  @media (mq.$from-large) {
    min-height: calc(552px / 2);
    padding: constants.$padding-04 40px;
    text-overflow: ellipsis;
  }
}

.AiCarouselOverflowItem-title {
  color: colors.$white;

  &--margin-01 {
    margin-bottom: constants.$margin-01;
  }

  &--inner-01 {
    margin-bottom: constants.$inner-01;
  }
}

.AiCarouselOverflowItem-description {
  color: colors.$white;
}

.AiCarouselOverflowItem-buttonWrapper {
  text-align: center;
}

.AiCarouselOverflowItem-button {
  display: inline-block;
  margin-top: constants.$margin-01;
}
</style>
