<template>
  <section ref="container" class="AiCarouselOverflow">
    <article class="Layout">
      <div class="Layout-section">
        <grid-item
          v-if="!carouselOnly"
          class="AiCarouselOverflow-block"
          sm="4"
          offset-md="1"
          md="3">
          <ai-typo
            v-if="title"
            as="h2"
            variant="heading-02"
            class="AiCarouselOverflow-title"
            style="order: 2">
            {{ title }}
          </ai-typo>
          <ai-typo
            v-if="subtitle"
            as="h3"
            variant="overline-01"
            class="AiCarouselOverflow-subtitle"
            style="order: 1">
            {{ subtitle }}
          </ai-typo>

          <ai-typo
            v-if="description"
            as="p"
            variant="paragraph-04-regular"
            class="AiCarouselOverflow-description"
            style="order: 2">
            {{ description }}
          </ai-typo>

          <ai-button
            v-if="ctaLabel && link"
            :as-link="true"
            :href="link"
            :label="ctaLabel"
            variant="secondary"
            :icon-right="'button-arrow-right' as IconName"
            light
            style="order: 2" />
        </grid-item>

        <grid-item
          v-if="items.length"
          sm="4"
          offset-md="1"
          :md="!carouselOnly ? 6 : 10">
          <ai-carousel
            v-model="currentStep"
            class="AiCarouselOverflow-carousel"
            :access-id="accessId"
            :items="items"
            :gutter="gutter"
            :mobile-button="mobileButton"
            :button-position="buttonPosition"
            :button-accessibility-label="accessibilityLabel"
            :slide-width="slideWidth"
            :arrows-right-position="arrowsRightPosition"
            :hide-progress-bar="hideProgressBar"
            arrows-top-position="20%"
            hide-gallery />
        </grid-item>
      </div>
    </article>

    <slot name="footer" />
  </section>
</template>

<script setup lang="ts">
import AiTypo from '../../atoms/AiTypo/AiTypo.vue';
import AiButton from '../../atoms/AiButton/AiButton.vue';
import type { IconName } from '../../atoms/AiIcon/types';
import AiCarousel from '../../molecules/AiCarousel/AiCarousel.vue';
import type { AiCarouselItem } from '../../molecules/AiCarousel/types';

import AiCarouselOverflowItem from './AiCarouselOverflowItem.vue';
import type { CarouselOverflowItemProps } from './types';

interface Props {
  accessId: string;
  items: CarouselOverflowItemProps[];
  title: string;
  subtitle?: string;
  description: string;
  mobileButton?: boolean;
  buttonPosition?: 'start' | 'center' | 'end';
  slideWidth?: number;
  component?: Component | keyof HTMLElementTagNameMap;
  modelValue: number;
  ctaLabel?: string;
  link?: string | null | undefined;
  carouselOnly?: boolean;
  arrowsRightPosition?: string;
  hideProgressBar?: boolean;
  accessibilityLabel?: string;
}

interface Emits {
  (event: 'update:modelValue', step: number): void;
}

const props = withDefaults(defineProps<Props>(), {
  arrowsRightPosition: '',
  carouselOnly: false,
  buttonPosition: 'start',
  mobileButton: false,
  component: AiCarouselOverflowItem,
  ctaLabel: '',
  link: null,
  slideWidth: 0.75,
  subtitle: '',
  accessibilityLabel: undefined,
});

const emits = defineEmits<Emits>();

// Adds some padding to the previous element to take into account the overflow
// to respect the design margins between sections
const container = ref<HTMLElement>();
onMounted(() => {
  if (!container.value) return;

  const previousSibling = container.value.previousElementSibling as
    | HTMLElement
    | undefined;

  if (!previousSibling) return;

  // Add 2 classes in order to have a heavier css selector below
  previousSibling.classList.add('AiCarouselOverflow-previous');
  previousSibling.classList.add('AiCarouselOverflow-previousSpacer');
});

const items = (props.items ?? []).map<AiCarouselItem>(item => ({
  component: props.component,
  key: `overflow-${item.key}`,
  props: { ...item },
}));

const isSmallDevice = useMatchMediaQuery('tablet');
const gutter = computed(() => (isSmallDevice.value ? 16 : 24));

const currentStep = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';

.AiCarouselOverflow-block {
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0;
  @media (mq.$from-medium) {
    margin-bottom: constants.$margin-05;
    justify-content: center;
  }
}
.AiCarouselOverflow {
  background: constants.$linear-gradient-stratos;
  padding-top: constants.$padding-07;
  padding-bottom: constants.$padding-07;
}

.AiCarouselOverflow-subtitle {
  color: colors.$gold-300;
  text-transform: uppercase;
}

.AiCarouselOverflow-title {
  color: colors.$white;
}

.AiCarouselOverflow-description {
  color: colors.$white;
}

.AiCarouselOverflow-carousel {
  margin-top: constants.$margin-04;

  @media (mq.$from-medium) {
    /*
      Negative margin for "overflow on top"
      Takes the carousel padding + wrapper (section) padding
      Behaviour validated by real Kaliop integrator
    */
    margin-top: calc(-1 * (constants.$padding-04 + constants.$padding-07));
  }
}
.AiCarouselOverflow-previous.AiCarouselOverflow-previousSpacer {
  padding-bottom: calc(constants.$padding-04 + constants.$padding-07);
}
</style>
