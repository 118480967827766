<template>
  <section
    class="Layout AiCarouselCardOnly"
    :class="[{ 'AiCarouselCardOnly--noPadding': noPadding }]">
    <article class="Layout-section">
      <grid-item
        v-if="title"
        sm="4"
        md="10"
        offset-md="1"
        class="AiCarouselCardOnly-title">
        <ai-typo as="h2" variant="heading-02">{{ title }}</ai-typo>
      </grid-item>
      <grid-item sm="4" md="10" offset-md="1">
        <ai-carousel
          v-if="items.length"
          :ref="accessId"
          v-model="currentStep"
          :access-id="accessId"
          hide-gallery
          :arrows-top-position="arrowsPosition"
          :items="items"
          :button-position="buttonPosition"
          :button-accessibility-label="accessibilityLabel"
          :mobile-button="mobileButton"
          :hide-navigation-button="hideNavigationButton"
          :slide-width="slideWidth"
          :hide-progress-bar="hideProgressBar"
          :drag-tolerance="dragTolerance"
          :gutter="gutter" />
      </grid-item>
    </article>
    <slot name="footer" />
  </section>
</template>

<script setup lang="ts">
import AiCard from '../../molecules/AiCard/AiCard.vue';
import type { AiCarouselItem } from '../../molecules/AiCarousel/types';

interface AiCarouselCardOnlyprops {
  accessId: string;
  title?: string;
  items: Record<string, unknown>[];
  component?: Component | keyof HTMLElementTagNameMap;
  gutter?: number;
  mobileButton?: boolean;
  modelValue: number;
  buttonPosition?: 'start' | 'center' | 'end';
  arrowsPosition?: string;
  hideNavigationButton?: boolean;
  hideProgressBar?: boolean;
  slideWidth: number;
  noPadding?: boolean;
  dragTolerance?: number;
  accessibilityLabel?: string;
}

interface Emits {
  (
    event: 'update:modelValue',
    step: AiCarouselCardOnlyprops['modelValue'],
  ): void;
}

const props = withDefaults(defineProps<AiCarouselCardOnlyprops>(), {
  arrowsPosition: '10%',
  component: AiCard,
  gutter: 12,
  mobileButton: false,
  noPadding: false,
  hideNavigationButton: false,
  hideProgressBar: false,
  title: '',
  buttonPosition: 'start',
  inverse: false,
  dragTolerance: undefined,
  accessibilityLabel: undefined,
});

const emits = defineEmits<Emits>();

const items = computed<AiCarouselItem[]>(() =>
  (props.items ?? []).map(item => ({
    component: props.component,
    key: `enhancement-${props.title}`,
    props: item,
  })),
);

const currentStep = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';

.AiCarouselCardOnly {
  padding-bottom: constants.$padding-07;
  padding-top: constants.$padding-07;

  &--noPadding {
    padding-bottom: 0;
    padding-top: 0;
  }
}
.AiCarouselCardOnly-title {
  margin-bottom: constants.$margin-04;
}
</style>
