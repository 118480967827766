import type { LocalePathFunction } from 'vue-i18n-routing';
import type { WithContext, Thing } from 'schema-dts';

import type {
  Accommodation,
  Facility,
  Hotel,
  HotelSpaActivity,
  Maybe,
} from '~/domains/graphql';

import { getBrandLogoName, getBrandName } from './brand.helper';

type BedDetails = {
  '@type': 'BedDetails';
  typeOfBed: string;
  numberOfBeds: number;
};

const schemaContext = 'https://schema.org';

export function extractHotelStructuredData(
  hotel: Hotel,
  baseUrl: string,
  localePath: LocalePathFunction,
): WithContext<Thing> {
  const isPhoneValid = Boolean(
    hotel.contact?.phonePrefix && hotel.contact?.phone,
  );
  const hasReviews = Boolean(hotel.reviews?.average);

  return {
    '@context': schemaContext,
    '@type': 'Hotel',
    name: hotel.name as string,
    description: hotel.description as string,
    address: {
      '@type': 'PostalAddress',
      streetAddress: hotel.address?.street as string,
      addressLocality: hotel.address?.city as string,
      postalCode: hotel.address?.zipCode as string,
      addressCountry: hotel.address?.country as string,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: hotel.address?.lat as string,
      longitude: hotel.address?.lng as string,
    },
    email: hotel.contact?.mail as string,
    telephone: isPhoneValid
      ? `${hotel.contact?.phonePrefix} ${hotel.contact?.phone}`
      : undefined,
    aggregateRating: hasReviews
      ? {
          '@type': 'AggregateRating',
          ratingValue: hotel.reviews?.average as number,
          reviewCount: hotel.reviews?.count as number,
        }
      : undefined,
    amenityFeature:
      hotel.facilities?.map(amenity => ({
        '@type': 'LocationFeatureSpecification',
        name: amenity.title as string,
      })) ?? [],
    checkinTime: hotel.checkInHour as string,
    checkoutTime: hotel.checkOutHour as string,
    brand: {
      '@type': 'Brand',
      name: getBrandName(hotel.brand),
    },
    logo: `${baseUrl}/assets/logo/${getBrandLogoName(hotel.brand)}.svg`,
    image: hotel.heroImage?.src as string,
    url: `${baseUrl}${localePath({
      name: 'hotel-hotelSlug',
      params: { hotelSlug: hotel.slug },
    })}`,
  };
}

export function extractHotelRoomStructuredData(
  room: Accommodation,
): WithContext<Thing> {
  const baseHotelRoomData: WithContext<Thing> = {
    '@context': schemaContext,
    '@type': 'HotelRoom',
    name: room.label as string,
    description: room.description as string,
    occupancy: {
      '@type': 'QuantitativeValue',
      value:
        (room.configuration?.[0]?.maxOccupancy?.adult ?? 0) +
        (room.configuration?.[0]?.maxOccupancy?.children ?? 0),
    },
    amenityFeature:
      room.topAmenities?.map(amenity => ({
        '@type': 'LocationFeatureSpecification',
        name: amenity as string,
      })) ?? [],
  };

  const beddings = getTypeOfBeddings(room.configuration);

  if (!beddings) {
    return baseHotelRoomData;
  }

  if (Array.isArray(beddings)) {
    return {
      ...baseHotelRoomData,
      // Schema doesn't HotelRoom with multiple bed types
      '@type': 'Suite',
      bed: beddings,
    };
  }

  return {
    ...baseHotelRoomData,
    bed: beddings,
  };
}

export function extractHotelFacilityStructuredData(
  facility: Facility,
  type?: Maybe<string>,
): WithContext<Thing> {
  return {
    '@context': schemaContext,
    '@type': type === 'Bars' ? 'BarOrPub' : 'Restaurant',
    name: facility.title as string,
    description: facility.description as string,
    servesCuisine: facility.cuisineType as string,
    hasMenu: facility.menu ? `https:${facility.menu}` : undefined,
    image: facility.picture?.src as string,
  };
}

export function extractHotelSpaActivityStructuredData(
  spaActivity: HotelSpaActivity,
): WithContext<Thing> {
  return {
    '@context': schemaContext,
    '@type': 'DaySpa',
    name: spaActivity.title as string,
    description: spaActivity.description as string,
    image: spaActivity.picture?.src as string,
  };
}

function getTypeOfBeddings(
  configuration: Accommodation['configuration'],
): BedDetails[] | BedDetails | null {
  const beddings = configuration?.[0]?.beddings;

  if (!beddings || (!beddings.doubleBed && !beddings.singleBed)) {
    return null;
  }

  if (beddings.doubleBed && beddings.singleBed) {
    return [
      {
        '@type': 'BedDetails',
        typeOfBed: 'DoubleBed',
        numberOfBeds: beddings.doubleBed,
      },
      {
        '@type': 'BedDetails',
        typeOfBed: 'SingleBed',
        numberOfBeds: beddings.singleBed,
      },
    ];
  }

  return {
    '@type': 'BedDetails',
    typeOfBed: beddings.doubleBed ? 'DoubleBed' : 'SingleBed',
    numberOfBeds: (beddings.doubleBed
      ? beddings.doubleBed
      : beddings.singleBed) as number,
  };
}
